import Modal from "@/components/Modal/index.jsx";
import {PropTypes} from "prop-types";
import HTMLParser from "../HTMLParser";

const unescapedText = text => {
  return new DOMParser()
    .parseFromString(text, "text/html")
    .documentElement.textContent.split("\n");
};

const SectionIngredientsBreakdown = ({id, body, image, title}) => {
  const closeIngredientsModal = () =>
    window.dispatchEvent(
      new CustomEvent(`${id}:modal:toggle`, {
        detail: {
          open: false,
        },
      }),
    );

  return (
    <Modal
      id={id}
      animationClass="slide-ingredients"
      className="fixed z-modal flex h-full w-full items-center justify-center md:left-1/2 md:top-1/2 md:max-h-[90vh] md:max-w-[60rem] md:-translate-y-1/2"
    >
      <div className="text-green-text relative flex h-full max-h-[90vh] w-full flex-col justify-center bg-white px-4 py-20 md:h-auto md:max-w-[60rem] md:rounded-[1.4rem] md:px-16">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute right-5 top-5 h-7 w-7 cursor-pointer fill-dark-green"
          onClick={closeIngredientsModal}
        >
          <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" />
        </svg>
        {title && title !== ""
          ? unescapedText(title).map((text, index) => (
              <h4 key={index} className="text-3xl">
                {text}
              </h4>
            ))
          : null}
        <div className="mt-3 flex max-h-full flex-col gap-2 overflow-y-scroll">
          <HTMLParser className="richtext" html={body} />

          {image && image.length > 0 ? (
            <img
              src={image}
              alt="Nutrition Label"
              className="mx-auto max-h-[70vh] md:max-h-[50vh]"
            />
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

SectionIngredientsBreakdown.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
};

export default SectionIngredientsBreakdown;
