import {renderStructuredElements} from "@/utils/render-helpers.jsx";
import SectionIngredientsBreakdown from "@/components/SectionIngredientsBreakdown";
import "./sections/section-ingredients-breakdown.scss";

document.addEventListener("DOMContentLoaded", () => {
  renderStructuredElements(
    "ingredients-modal-area",
    SectionIngredientsBreakdown,
  );
});
